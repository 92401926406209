@use 'src/assets/scss/mixins/buttons';
@use 'src/assets/scss/colors';

.mdc-flat-button {
  padding: 0px 25px !important;
}

// ==========================================================================================
// Colors
// ==========================================================================================

@include buttons.qaroni-build-mat-color-button(
  'mat-shop',
  colors.$qbono-one,
  colors.$qbono-one-contrast
);
