@use 'src/assets/scss/colors';

.card-ui {
  border-radius: 16px;
  width: 388px;
}

.card-ui-header {
  background: linear-gradient(
    99.97deg,
    lighten(colors.$snackbar-primary, 65%) 2.95%,
    lighten(colors.$snackbar-primary, 40%) 100%
  );
  height: 130px;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.card-ui-header-tag {
  position: absolute;
  top: 0;
  width: 50%;
  height: 36px;
  color: colors.$theme-primary-contrast-500;
  background: linear-gradient(
    90deg,
    lighten(colors.$snackbar-primary, 5%) 2.95%,
    colors.$snackbar-primary 100%
  );
  padding: 3px 10px 3px 10px;
  border-top-left-radius: 16px;
  border-bottom-right-radius: 16px;
  gap: 10px;
  text-align: center;
  display: gap;
  place-content: center;
}

.card-ui-header-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60%;
  padding: 0px 25px;
}

.card-ui-header-info-content {
  gap: 20px;
}

.card-ui-header-info-content-total {
  font-size: 46px;
  font-weight: 500;
}

.text-ui-primary {
  color: colors.$snackbar-primary !important;
}

.text-ui-secondary {
  color: colors.$snackbar-secondary;
}

.text-ui-danger {
  color: colors.$danger-red;
}

.card-ui-header-info-content .info-aditional {
  margin-top: -15px;
}

.card-ui-header-info-content .value,
.card-ui-header-info .date {
  font-size: 13px;
  font-weight: 400;
}

.card-ui-header-info-content .price {
  font-size: 18px;
  font-weight: 500;
}

.card-ui-header-info mat-icon {
  font-size: 14px;
}

.card-ui-header-info .date {
  margin-left: -5px;
}

.card-ui-body {
  padding: 30px 25px;
  gap: 12px;
}

.card-ui-body-item {
  border-radius: 16px;
  background: linear-gradient(
    91.91deg,
    colors.$item-wallet-min -11.51%,
    colors.$item-wallet-max 98.53%
  );
  padding: 10px 15px 10px 15px;
  gap: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

.card-ui-body-item-text .download {
  color: colors.$text-disabled;
  font-size: 12px;
  font-weight: 400px;
  line-height: 14px;
  text-align: start;
}

.card-ui-body-item-text .wallet {
  color: colors.$item-wallet-text;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}

.card-ui-footer {
  padding: 0px 25px;
  gap: 10px;
  margin-bottom: 25px;
}

.item-pdf {
  background: linear-gradient(
    99.97deg,
    lighten(colors.$snackbar-primary, 65%) 2.95%,
    lighten(colors.$snackbar-primary, 40%) 100%
  );
}

.item-pdf .wallet {
  font-weight: 500;
}

.card-ui-body-item .icon-custom {
  background: colors.$item-wallet-icon;
  width: 44px;
  height: 44px;
  padding: 7px;
  border-radius: 16px;
}

.disabled-ui {
  border-radius: 16px !important;

  .card-ui-header {
    border-radius: 16px;
  }
}

.disabled-ui-header {
  border-bottom-right-radius: 16px;
}

.disabled-ui-header {
  .card-ui-header-info-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
    place-content: center;
    text-align: start;
  }

  .not-bonos {
    font-size: 18px;
    font-weight: 500;
  }

  .not-generation-bonos {
    font-size: 13px;
    font-weight: 500;
  }
}
