@use 'src/assets/scss/colors';

.gm-style-iw {
  .gm-ui-hover-effect {
    background-color: colors.$danger-red !important;
    height: 26px !important;
    width: 26px !important;
    position: absolute !important;
    right: 3px;
    top: 3px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin: auto !important;
      height: 18px !important;
      width: 18px !important;
    }
  }
}
