@use 'src/assets/scss/colors';
@use 'src/assets/scss/mixins/breakpoints';

$spacing: 430px;

.filter-bg-container {
  background-color: colors.$bg-custom !important;
}

.filter-border-b-gray {
  border-bottom: 1px solid colors.$qaroni-border-gray;
}

.filter-bg-100 {
  background-color: colors.$qaroni-gray-dark-0;
}

.filter-min-h-container {
  min-height: calc(100vh - 56px - 94px);
}

.-mt-12 {
  margin-top: -12px;
}

.-mt-20px {
  margin-top: -20px;
}

.filter-sidenav-options {
  max-width: 420px;
  width: 100%;
}

.filter-p-sidenav {
  padding: 10px 22px 0px 22px;

  @include breakpoints.md {
    padding: 32px 16px 0px 16px;
  }
}

.btn-filter-search {
  width: 100%;
  height: 55px;
  border-radius: 50px;
  background-color: white;
  transition: 0.5ms background ease-in-out;

  @include breakpoints.lg {
    background-color: colors.$qaroni-btn-search;
  }
}

.py-10-custom {
  padding: 0px 10px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
  border-radius: 50px !important;

  @include breakpoints.lg {
    background-color: transparent !important;
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-width: 0px;
  background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.icons-filter {
  color: colors.$qaroni-gray-dark;
  width: 17px !important;
  height: 17px !important;
}

.btn-icon {
  background-color: transparent;
  outline: none;
  border: 0px;
}

.text-red {
  color: colors.$danger-red;
}

.box-icon {
  width: 24px;
  height: 24px;
  display: grid;
  place-content: center;

  .icons-filter {
    width: 20px !important;
    height: 20px !important;
  }
}

.filter-text-gray {
  color: colors.$qaroni-gray-dark !important;
}

.filter-text-gray-2 {
  color: colors.$qaroni-gray-dark-2 !important;
}

.filter-border-gray {
  border: 1px solid colors.$qaroni-border-gray;
  border-radius: 5px;
}

.mat-mdc-form-field-subscript-wrapper {
  height: 0px;
}

.filter-nearby {
  display: grid;
  grid-template-rows: 1fr;
  gap: 20px;
}

.filter-container-btn,
.filter-bg-white {
  background-color: white;
}

.mat-drawer-content {
  min-height: 100%;
  overflow: hidden !important;
}

.filter-card-merchant {
  gap: 12px;
  padding: 12px;
}

.container-bg-image {
  border-radius: 4px;
}

.filter-merchant-info {
  .mat-h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .mat-h4 {
    font-size: 12px;
    line-height: 15.6px;
    font-weight: 300;
  }
}

.filter-card-btn-action {
  gap: 8px;
}

qaroni-merchants-list-ui {
  height: 100%;
}

.filter-header-options {
  z-index: 50 !important;
}

.filter-radius-8 {
  border-radius: 8px;
}

.filter-img-preview {
  width: 84px;
  height: 80px;
}

.filter-gap-12 {
  gap: 12px;
}

.filter-text-12 {
  font-size: 12px;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-100 {
  opacity: 1;
}

.filter-no-results {
  z-index: 0;
  left: 40%;
  top: 50%;
  text-align: center;
}

.filter-icon-place-no-result,
.filter-icon-box {
  display: block;
  width: 30px;
  height: 2px;
  background-color: colors.$qaroni-gray-dark-2;
  left: 44px;
  top: 8px;
  transform: rotate(35deg);
}

.filter-icon-box {
  left: 5px;
  top: 18px;
}

.filter-bg-transparent {
  background-color: transparent;
}

.filter-btn-options {
  width: 40px !important;
  height: 40px !important;
  border: none;
  outline: none;
  display: grid;
  place-content: center;
}

.filter-mobile-options {
  height: auto;
  transition: max-height 0.6s ease-in-out;
}

.filter-mobile-hide {
  max-height: 0px !important;
  padding: 0px;
}

.filter-mobile-show {
  max-height: 475px !important;
}

.z-index-100 {
  z-index: 100;
}

.z-index-40 {
  z-index: 40 !important;
}

.filter-h-35 {
  height: 35px !important;
}

.filter-w-custom-100 {
  width: 100%;

  @include breakpoints.lg {
    width: auto;
  }
}

.filter-btn-info {
  color: colors.$qaroni-gray-dark;
  transition: color 0.5s ease-in;
}

.icon-search-close {
  width: 20px !important;
  height: 20px !important;
}

.filter-h-sidenav {
  height: 100%;

  @include breakpoints.lg {
    transform: translateY(64px);
  }
}

.z-10 {
  z-index: 10;
}

.m-h-64 {
  min-height: 64px;
}

.filter-sidenav-options {
  max-width: $spacing;
  width: 100%;
}

.filter-p-sidenav {
  padding: 10px 22px 0px 22px;

  @include breakpoints.md {
    padding: 32px 16px 0px 32px;
  }
}

.filter-sidenav-layout {
  width: 100%;

  @include breakpoints.lg {
    width: calc(100% - $spacing);
    transform: translateX($spacing);
  }
}

.filter-nearby-selector {
  width: 250px !important;
}

.filter-sidenav-content,
.filter-mobile-sidenav-content {
  padding: 20px;
  padding-bottom: 5px;
  gap: 20px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @include breakpoints.lg {
    padding-bottom: 20px;
    overflow: hidden;
  }
}

.filter-header-map {
  z-index: 40 !important;
  margin: 20px;
  top: 90px;
  transition: 0.5s top ease-in-out;

  @include breakpoints.lg {
    top: 0px;
  }
}

.filter-mobile-container {
  transition: background 0.5ms ease-in-out;
  transform: translateY(64px);
}

.example-alternate {
  background: rgba(127, 127, 127, 0.3);
}

.filter-mx-h-container {
  max-height: calc(100vh - 148px) !important;
}
